import {makeStyles} from "@material-ui/core/styles"
import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import AddAnalogToDigitalExpr from "../modals/AnalogToDigitalExprModal"
import DeleteModal from "../../../../components/modals/DeleteModal"
import {tableIcons} from "../../../../components/tables/tableIcons"
import {useAxios} from "../../../../utils/request"

const useStyles = makeStyles((theme) => ({
    table: {
        padding: "0px",
    },
}))

export default function ExpressionsTable(props) {
    const classes = useStyles()
    const url = useSelector((state) => state.baseUrl)
    const [responseData, setResponseData] = useState([])
    const [modalAddAnalogToDigitalExpr, setModalAddAnalogToDigitalExpr] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const {refetch, isLoading} = useAxios(
        ["analog_to_digital_expressions", props.id],
        {
            url: `/analog_to_digital_expressions/?unit=${props.id}`,
            method: "GET",
        },
        {enabled: true},
        (res) => {
            setResponseData(res.data.results)
        }
    )

    useEffect(
        (_) => {
            refetch()
        },
        // eslint-disable-next-line
        [modalAddAnalogToDigitalExpr, modalDelete]
    )

    const deleteRow = (event, rowData) => {
        setModalDelete(rowData)
    }

    return (
        <>
            {modalDelete && (
                <DeleteModal
                    open={!!modalDelete}
                    handleClose={() => {
                        setModalDelete(false)
                    }}
                    url={url + `/analog_to_digital_expressions/${modalDelete.id}/`}
                    deleteText="DELETE EXPRESSION"
                    header={`Delete expression ${modalDelete.name}?`}
                    alertText={"Analog to Digital expression was deleted"}
                />
            )}

            {modalAddAnalogToDigitalExpr && (
                <AddAnalogToDigitalExpr
                    id={props.id}
                    open={modalAddAnalogToDigitalExpr}
                    edit={editModal}
                    handleClose={() => {
                        setModalAddAnalogToDigitalExpr(false)
                        setEditModal(false)
                    }}
                />
            )}
            <MaterialTable
                className={classes.table}
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {title: "Variable", field: "variable"},
                    {title: "Limit number", field: "limit_number"},
                    {title: "Substitute for 0", field: "substitute_for_0"},
                    {title: "Substitute for 1", field: "substitute_for_1"},
                ]}
                data={responseData}
                title="Analog to Digital expressions"
                isLoading={isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add Analog to Digital expressions",
                        isFreeAction: true,
                        onClick: (event) => setModalAddAnalogToDigitalExpr(true),
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit expression",
                        onClick: (event, rowData) => {
                            setModalAddAnalogToDigitalExpr(true)
                            setEditModal(rowData)
                        },
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete expression",
                        onClick: deleteRow,
                    },
                ]}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
