import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    IconButton,
    Tooltip,
    Typography
} from "@material-ui/core"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useRef, useEffect} from "react"
import {useDispatch} from "react-redux"
import {useParams} from "react-router"
import {setAlert} from "../../../context/alert"
import {useAxios} from "../../../utils/request"
import {
    smsIntervalTime,
    renameType,
} from "../../../utils/enumMapping"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    item : {
        marginTop: 10,
        marginRight: 10,
        width: "calc(50% - 16px)",
    },
    tooltipButton: {
        position: 'absolute',
        right: theme.spacing(1.5),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    passwordInput: {
        position: 'absolute',
        left: theme.spacing(4),
        bottom: theme.spacing(1),
    },
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
}))

export default function TransitionsModal(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    let {id} = useParams()

    const [name, setName] = React.useState("")
    const [phone_number, setPhone] = React.useState("")
    const [gprs_remote_controller_id, setGprsRemoteControllerId] = React.useState("")
    const [sms_interval_time, setSmsIntervalTime] = React.useState(111)
    const [sms_center_number, setSmsCenterNumber] = React.useState("")
    const [internal_datetime, setInternalDatetime] = React.useState(`${moment().year()}-01-01T00:00`)
    const [sms_inquiry_password, setSmsInquiryPassword] = React.useState("")
    const [password, setPassword] = React.useState("")

    const requestFlagRef = useRef();
    const intervalId1 = useRef(null);
    const intervalId2 = useRef(null);
    const timeoutId = useRef(null);
    const getInterval = 2500;
    const postInterval = 60000;
    const timeoutInterval = 119500;
    const maxGetIntervalTicks = Math.floor(timeoutInterval / getInterval);
    const [getIntervalTicks, setGetIntervalTicks] = React.useState(0)

    const getUnitRequest = useAxios("getUnit", {
        url: `/units/${id}/`,
        method: "GET",
    })

    const postPendingUnitRequest = useAxios("postUnit", {
        url: `/pending_unit/`,
        method: "POST",
        data: {name, phone_number, sms_interval_time, sms_center_number, internal_datetime, sms_inquiry_password, gprs_remote_controller_id, password, "is_new": props.edit ? false : true, ...(id && { id })},
    })

    const confirmCreationOfUnitPendingRequest = useAxios("getUnitPending", {
        url: `/pending_unit/${phone_number}/`,
        method: "POST",
        data: {...(id && { id })}
    })

    const setUnitDetail = () => {
        getUnitRequest.refetch()
    }

    useEffect(() => {
        if (getUnitRequest.data) {
            const {data} = getUnitRequest.data
            setName(data.name)
            setPhone(data.phone_number)
            setGprsRemoteControllerId(data.gprs_remote_controller_id)
            setSmsIntervalTime(data.sms_interval_time)
            setSmsCenterNumber(data.sms_center_number)
            setInternalDatetime(data.internal_datetime ? data.internal_datetime.slice(0, 16) : `${moment().year()}-01-01T00:00`)
            setSmsInquiryPassword(data.sms_inquiry_password)
            setPassword("")
        }
        // eslint-disable-next-line
    }, [getUnitRequest.isFetching])

    useEffect(() => {
        if (postPendingUnitRequest.isSuccess && postPendingUnitRequest.data.status === 200) {
            clearInterval(intervalId2.current);
            clearInterval(intervalId1.current);
            requestFlagRef.current = false;
            clearTimeout(timeoutId.current);

            dispatch(
                setAlert({
                    status: "success",
                    message: `Unit was ${props.edit ? "updated" : "created"}.`,
                })
            )
            props.handleClose()
        }
        if (postPendingUnitRequest.isSuccess && postPendingUnitRequest.data.status === 201) {
            if (requestFlagRef.current) {
                intervalId1.current = setInterval(postPendingUnitRequestInterval, postInterval);
                intervalId2.current = setInterval(confirmCreationOfUnitPendingRequestInterval, getInterval);
                timeoutId.current = setTimeout(() => {
                    clearInterval(intervalId2.current);
                    clearInterval(intervalId1.current);
                }, timeoutInterval);
            }
        }
        if (postPendingUnitRequest.isError) {
            requestFlagRef.current = false;
            dispatch(
                setAlert({
                    severity: "error",
                    message: postPendingUnitRequest.error.data["detail"],
                })
            )
            props.handleClose()
        }
        // eslint-disable-next-line
    }, [postPendingUnitRequest.isFetching])

    useEffect(() => {
        if (requestFlagRef.current) {
            dispatch(
                setAlert({
                    severity: "info",
                    autoHideDuration: 120000,
                    pending: true,
                    icon: <CircularProgress size="1.5rem" style={{'color': 'white'}}/>,
                    message: `Request pending.`,
                })
            )
        }
        // eslint-disable-next-line
    }, [requestFlagRef.current])

    useEffect(() => {
        if (confirmCreationOfUnitPendingRequest.isFetching === true) {
            setGetIntervalTicks(getIntervalTicks + 1);
        }

        if (confirmCreationOfUnitPendingRequest.isSuccess && confirmCreationOfUnitPendingRequest.data.data["request_pending"] === 0) {
            requestFlagRef.current = false;
            clearTimeout(timeoutId.current);
            
            requestFlagRef.current = false;
            dispatch(
                setAlert({
                    status: "success",
                    message: `Unit was ${props.edit ? "updated" : "created"}.`,
                })
            )
            props.handleClose();
        }
        // eslint-disable-next-line
    }, [confirmCreationOfUnitPendingRequest.isFetching])

    useEffect(() => {
        if (getIntervalTicks === maxGetIntervalTicks) {
            requestFlagRef.current = false;
            dispatch(
                setAlert({
                    severity: "error",
                    message: `Unit wasn't ${props.edit ? "updated" : "created"}.`,
                })
            )
            setGetIntervalTicks(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIntervalTicks])

    const handleNameChange = (event) => setName(event.target.value)
    const handlePhoneChange = (event) => setPhone(event.target.value)
    const handleGprsRemoteControllerIdChange = (event) => setGprsRemoteControllerId(event.target.value)
    const handleSmsIntervalTimeChange = (event) => setSmsIntervalTime(event.target.value)
    const handleSmsCenterNumberChange = (event) => setSmsCenterNumber(event.target.value)
    const handleInternalDatetimeChange = (event) => setInternalDatetime(event.target.value)
    const handleSmsInquiryPasswordChange = (event) =>  setSmsInquiryPassword(event.target.value)
    const handlePasswordChange = (event) => setPassword(event.target.value)

    const handleSubmit = (event) => {
        requestFlagRef.current = true;
        
        postPendingUnitRequestInterval();
    }

    const postPendingUnitRequestInterval = () => {
        if (requestFlagRef.current) {
            postPendingUnitRequest.refetch();
        }
    }

    const confirmCreationOfUnitPendingRequestInterval = () => {
        if (requestFlagRef.current) {
            confirmCreationOfUnitPendingRequest.refetch();
        }
    }

    const nameValidation = () => {
        return name === '' || name == null
    }
    const phoneNumberValidation = () => {
        return phone_number === '' || phone_number == null || phone_number.length > 13 || phoneNumberLengthValidation() || phoneNumberFormatValidation()
    }
    const phoneNumberLengthValidation = () => {
        return phone_number != null && phone_number.length > 13
    }
    const phoneNumberFormatValidation = () => {
        return !/^\+\d{9,12}$/.test(phone_number)
    }
    const smsCenterNumberValidation = () => {
        // eslint-disable-next-line eqeqeq
        return (sms_center_number === '' || sms_center_number == null) && sms_interval_time != 111
    } 
    const smsIntervalTimeValidation = () => {
        // eslint-disable-next-line eqeqeq
        return (sms_center_number !== '' && sms_center_number != null) && sms_interval_time == 111
    } 
    const smsInqiuryPasswordValidation = () => {
        return sms_inquiry_password === null || sms_inquiry_password.length !== 4 || !/^\d+$/.test(sms_inquiry_password)
    }
    const passwordValidation = () => {
        return password === null || password.length !== 4 || !/^\d+$/.test(password)
    }
    const unitModalValidation = () => {
        return nameValidation() || phoneNumberValidation() || passwordValidation() || smsInqiuryPasswordValidation() || smsCenterNumberValidation() || smsIntervalTimeValidation()
    }

    useEffect(() => {
        if (props.edit) {
            setUnitDetail()
        }
        // eslint-disable-next-line
    }, [props.edit])

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="sm"
                disableBackdropClick={requestFlagRef.current}
                disableEscapeKeyDown={requestFlagRef.current}
            >
                <DialogTitle id="scroll-dialog-title">
                    {(props.edit ? "Edit " : "Add ") + " unit"}
                    {(props.edit ? <Tooltip 
                        title={
                        <React.Fragment>
                            <Typography color="inherit">Editing Units</Typography>
                            <div><b>{"If the user closes the application and does not wait for the evaluation of the request, the process will be aborted!"}</b></div>
                            <br />
                            <div>{"1. When editing a unit, a message is sent to the unit, which sends back a response about successful editing."}</div>
                            <div>{"2. If the unit does not respond within one minute, the process is automatically repeated once more."}</div>
                            <div>{"3. If you still receive an error message, either you entered the wrong password or the unit did not have time to respond and the unit was not modified."}</div>
                            <div>{"4. When changing a phone number, the new number must be unique."}</div>
                            <div>{"5. If only the name, phone number, sms inquiry password or any combination of those three are changed, the SMS is not sent to the unit, and if the new number is unique, the unit is adjusted immediately."}</div>
                        </React.Fragment>
                        }
                    >
                        <IconButton edge="end" color="inherit" className={classes.tooltipButton}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip> : <Tooltip 
                        title={
                        <React.Fragment>
                            <Typography color="inherit">Creating Units</Typography>
                            <div><b>{"If the user closes the application and does not wait for the evaluation of the request, the process will be aborted!"}</b></div>
                            <br />
                            <div>{"1. When a new unit is created, a message is sent to the unit, which sends back a response about the successful creation."}</div>
                            <div>{"2. If the unit does not respond within one minute, the process is automatically repeated once more."}</div>
                            <div>{"3. If you still receive an error message, either you entered the wrong password or the unit did not have time to respond and the new unit was not created."}</div>
                            <div>{"4. Phone number must be always unique."}</div>
                        </React.Fragment>
                        }
                    >
                        <IconButton edge="end" color="inherit" className={classes.tooltipButton}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>)}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <Box>
                            <TextField
                                error={nameValidation()}
                                value={name}
                                required
                                label="Name"
                                className={classes.item}
                                onChange={handleNameChange}
                                disabled={requestFlagRef.current}
                            />
                            <TextField
                                error={phoneNumberValidation()}
                                value={phone_number}
                                required
                                label="Phone number"
                                className={classes.item}
                                onChange={handlePhoneChange}
                                helperText={
                                    phoneNumberLengthValidation()
                                        ? "Number is too long!"
                                        : phoneNumberFormatValidation()
                                            ? "Number with country code is required"
                                            : ""
                                }
                                disabled={requestFlagRef.current}
                            />

                            <TextField
                                value={gprs_remote_controller_id}
                                required
                                label="Station Number"
                                className={classes.item}
                                onChange={handleGprsRemoteControllerIdChange}
                                helperText="The number must match the one on the remote control unit. It must be set correctly to receive data from the unit."
                                disabled={requestFlagRef.current}
                            />
                        </Box>
                        <Box>
                            <FormControl 
                                className={classes.item} 
                                fullWidth 
                            >
                                <InputLabel>SMS interval time</InputLabel>
                                <Select
                                    onChange={handleSmsIntervalTimeChange}
                                    value={sms_interval_time}
                                    renderValue={(selected) => renameType(selected)}
                                    disabled={requestFlagRef.current}
                                    error={smsIntervalTimeValidation()}
                                    
                                >
                                    {Object.entries(smsIntervalTime).map(
                                        ([key, item]) => {
                                            return (
                                                <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            
                            <TextField
                                error={smsCenterNumberValidation()}
                                value={sms_center_number}
                                label="SMS center number"
                                className={classes.item}
                                onChange={handleSmsCenterNumberChange}
                                disabled={requestFlagRef.current}
                            />
                        </Box>
                        <Box>
                            <TextField
                                required
                                id={`internal-datetime`}
                                label="Time & Date"
                                className={classes.item}
                                size="small"
                                value={internal_datetime}
                                type="datetime-local"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleInternalDatetimeChange}
                                disabled={requestFlagRef.current}
                            />
                            <TextField
                                error={smsInqiuryPasswordValidation()}
                                value={sms_inquiry_password}
                                type="number"
                                required
                                size="small"
                                label="SMS inquiry password"
                                className={classes.item}
                                onChange={handleSmsInquiryPasswordChange}
                                disabled={requestFlagRef.current}
                            />
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={2}>
                        <TextField
                            error={passwordValidation()}
                            value={password}
                            type="number"
                            required
                            size="small"
                            label="Password"
                            className={classes.passwordInput}
                            onChange={handlePasswordChange}
                            disabled={requestFlagRef.current}
                        />
                        <Grid item>
                            <Button
                                disabled={requestFlagRef.current}
                                variant="contained"
                                color={requestFlagRef.current ? "info" : "secondary"}
                                onClick={props.handleClose}
                            >
                                cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={unitModalValidation() || requestFlagRef.current}
                                variant="contained"
                                color={unitModalValidation() || requestFlagRef.current ? 'info' : 'primary'}
                                onClick={handleSubmit}
                            >
                                {(props.edit ? "Edit " : "Add ") + " unit"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}
