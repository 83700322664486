import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../../../context/alert"
import {useAxios} from "../../../../utils/request"

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "25rem",
        width: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    expression: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        // paddingT: 10,
    },
}))

export default function AddAnalogToDigitalExpr(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props.edit ? props.edit.name : "")
    const [variable, setVariable] = useState(
        props.edit ? props.edit.variable : ""
    )
    const [limit_number, setLimitNumber] = useState(props.edit ? props.edit.limit_number : 0)
    const [substitute_for_0, setSubstituteFor0] = useState(props.edit ? props.edit.substitute_for_0 : "")
    const [substitute_for_1, setSubstituteFor1] = useState(props.edit ? props.edit.substitute_for_1 : "")
    const id = props.edit ? props.edit.id + "/" : ""

    const {refetch} = useAxios(
        ["analog_to_digital_expressions", id],
        {
            url: `/analog_to_digital_expressions/${id}`,
            method: props.edit ? "PUT" : "POST",
            data: {
                unit: props.id,
                name,
                variable,
                limit_number,
                substitute_for_0,
                substitute_for_1,
            },
        },
        {},
        () => {
            dispatch(
                setAlert({
                    status: "success",
                    message: props.edit
                        ? "Expression was updated"
                        : "New Analog To Digital Expression was created.",
                })
            )
        },
        () => {
            dispatch(
                setAlert({
                    severity: "error",
                    message: "Something went wrong.",
                })
            )
        },
        () => {
            props.handleClose()
        }
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        refetch()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {props.edit ? "Update " : "Add "} Analog To Digital Expression
            </DialogTitle>
            <DialogContent dividers={true}>
                <div className={classes.root} id="add_expression_form">
                    <TextField
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        label="Name:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />

                    <TextField
                        value={variable}
                        onChange={(e) => {
                            setVariable(e.target.value)
                        }}
                        label="Variable:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />
                    <TextField
                        value={limit_number}
                        onChange={(e) => {
                            setLimitNumber(e.target.value)
                        }}
                        label="Limit number:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />
                    <TextField
                        value={substitute_for_0}
                        onChange={(e) => {
                            setSubstituteFor0(e.target.value)
                        }}
                        label="Substitute for 0:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />
                    <TextField
                        value={substitute_for_1}
                        onChange={(e) => {
                            setSubstituteFor1(e.target.value)
                        }}
                        label="Substitute for 1:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            form="add_expression_form"
                            variant="contained"
                            color="primary"
                        >
                            {props.edit ? "Update " : "Add "} Analog To Digital Expression
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
